import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Form as RBSForm } from "react-bootstrap"
import Select from "react-select"
import {
  Button,
  Card,
  CardBody,
  Col,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"
import TurnDownService from "turndown"
// import Breadcrumbs from "../../components/Common/Breadcrumb"

// MD Editor
import MarkdownIt from "markdown-it"
import MDEditor from "react-markdown-editor-lite"
import "react-markdown-editor-lite/lib/index.css"

import { SaveToast } from "components/Common/SaveToast"
import { Form, Formik } from "formik"
import * as Yup from "yup"
import { programType } from "../../common/data/dropdownVals"
import UpdateModal from "../../components/Common/UpdateModal"
import ViewDetails from "./ViewDetails"

import SubmitLoader from "components/Common/SubmitLoader"
import {
  createTrainingProgram,
  getTrainingProgram,
  updateTrainingProgram,
} from "helpers/backendHelpers/trainingProgram"
import { IMAGE_URL } from "helpers/urlHelper"

const mdParser = new MarkdownIt()
const turndownService = new TurnDownService()

const TrainingProgramModal = props => {
  const [isEdit, setIsEdit] = useState(false)
  const [isView, setIsView] = useState(false)
  const [programId, setProgramId] = useState(0)
  const [updateModal, setUpdateModal] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [isProgramFree, setIsProgramFree] = useState(true)
  const [temp, setTemp] = useState(false)

  const [genreOptions, setGenreOptions] = useState([
    {
      value: "Student",
      label: "Student",
    },
    {
      value: "Parent",
      label: "Parent",
    },
    {
      value: "Teacher",
      label: "Teacher",
    },
  ])

  const [form, setForm] = useState({
    tp_programTitle: "",
    tp_description: "",
    tp_description_html: "",
    tp_typeOfProgram: "",
    tp_whoCanAttend: "",
    tp_whoCanAttend_html: "",
    tp_benefitsOfProgram: "",
    tp_benefitsOfProgram_html: "",
    tp_duration: "",
    tp_totalMarks: "",
    tp_passingMarks: "",
    tp_marksPerQuestion: "",
    tp_certificateTemplate: { fileName: "", file: {} },
    tp_programImage: { fileName: "", file: {} },
    tp_programDetailTemplate: { fileName: "", file: {} },
    tp_isFree: true,
    tp_price: "",
    tp_whoCanAttendTraining: "",
  })

  useEffect(() => {
    document.getElementById("training-program").classList.add("mm-active")
    let { type, id } = props.match.params || {}

    switch (type) {
      case "edit":
        setIsEdit(true)
        setIsView(false)
        setProgramId(parseInt(id))
        break
      case "view":
        setIsView(true)
        setIsEdit(false)
        setProgramId(parseInt(id))
        break
      case "add":
        document.getElementById("type-free").checked = true
        break
      default:
        setIsView(false)
        setIsEdit(false)
        setProgramId(0)
        break
    }

    if (id) {
      fetchTrainingProgramForEdit(id)
    }
  }, [isEdit])

  const fetchTrainingProgramForEdit = async tp_id => {
    try {
      const response = await getTrainingProgram(tp_id)
      let { trainingProgram } = response.data || {}
      trainingProgram = trainingProgram || {}
      trainingProgram["tp_certificateTemplate_old"] =
        trainingProgram["tp_certificateTemplate"]
      trainingProgram["tp_certificateTemplate"] = { fileName: "", file: {} }

      trainingProgram["tp_programImage_old"] =
        trainingProgram["tp_programImage"]
      trainingProgram["tp_programImage"] = { fileName: "", file: {} }

      trainingProgram["tp_programDetailTemplate_old"] =
        trainingProgram["tp_programDetailTemplate"]
      trainingProgram["tp_programDetailTemplate"] = { fileName: "", file: {} }

      trainingProgram["tp_price"] =
        trainingProgram["tp_price"] === 0 ? "" : trainingProgram["tp_price"]

      if (trainingProgram?.tp_whoCanAttendTraining?.length) {
        trainingProgram.tp_whoCanAttendTraining =
          trainingProgram.tp_whoCanAttendTraining.map(genre => {
            return { label: genre, value: genre }
          })
      }

      if (isEdit) {
        if (trainingProgram.tp_isFree === true) {
          document.getElementById("type-free").checked = true
          setIsProgramFree(true)
        } else if (trainingProgram.tp_isFree === false) {
          document.getElementById("type-paid").checked = true
          setIsProgramFree(false)
        }
        trainingProgram["tp_description"] = turndownService.turndown(
          trainingProgram.tp_description
        )
        trainingProgram["tp_whoCanAttend"] = turndownService.turndown(
          trainingProgram.tp_whoCanAttend
        )
        trainingProgram["tp_benefitsOfProgram"] = turndownService.turndown(
          trainingProgram.tp_benefitsOfProgram
        )
      }
      return setForm(trainingProgram)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Fetching Program Details"
      setForm(form)
      alert("error")
      return SaveToast({ message, type: "error" })
    }
  }

  const handleAddTrainingProgramSubmit = async data => {
    try {
      setSubmitLoading(true)
      const response = await createTrainingProgram(data)
      let message = response?.message || "Program Added Successfully"
      SaveToast({ message, type: "success" })
      setSubmitLoading(false)
      props.history.push("/training-program")
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Adding Program"
      setSubmitLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleEditTrainingProgramSubmit = async (id, data) => {
    if (!id) {
      return SaveToast({
        message: "Please enter Program Id",
        type: "error",
      })
    }
    try {
      setSubmitLoading(true)
      const response = await updateTrainingProgram(id, data)
      let message = response?.message || "Program Updated Successfully"
      SaveToast({ message, type: "success" })
      setSubmitLoading(false)
      props.history.push("/training-program")
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Adding Program"
      setSubmitLoading(false)
      return SaveToast({ message: message, type: "error" })
    }
  }

  const handleUpdateProgram = () => {
    setUpdateModal(false)
    SaveToast({ message: "Program Updated Successfully", type: "success" })
    props.history.push("/training-program")
  }

  return (
    <React.Fragment>
      <UpdateModal
        show={updateModal}
        onUpdateClick={handleUpdateProgram}
        onCloseClick={() => setUpdateModal(false)}
      />
      <div className="page-content">
        <div
          className="container-fluid"
          style={submitLoading ? { position: "relative", opacity: "0.8" } : {}}
        >
          {/* <Breadcrumbs title="Training Program" breadcrumbItem="Program" /> */}
          {submitLoading ? <SubmitLoader /> : <></>}
          {isView ? (
            <>
              <ViewDetails programData={form} {...props} />
            </>
          ) : (
            <Card>
              <CardBody>
                <Formik
                  enableReinitialize={true}
                  initialValues={form}
                  validationSchema={Yup.object({
                    tp_programTitle: Yup.string().required(
                      "Please Enter Training Program Title"
                    ),
                    tp_description: Yup.string().required(
                      "Please Enter Program Description"
                    ),
                    tp_typeOfProgram: Yup.string()
                      .oneOf(programType, "Please Select Valid Type")
                      .required("Please Select Type Of Program"),
                    tp_whoCanAttend: Yup.string().required(
                      "Please Enter Who Can Attend"
                    ),
                    tp_benefitsOfProgram: Yup.string().required(
                      "Please Enter Benefits Of Program"
                    ),
                    tp_duration: Yup.string().required(
                      "Please Enter Duration Of Program"
                    ),
                    tp_totalMarks: Yup.string().required(
                      "Please Enter Total Marks"
                    ),
                    tp_marksPerQuestion: Yup.string().required(
                      "Please Enter Marks Per Question"
                    ),
                    tp_passingMarks: Yup.string().required(
                      "Please Enter Passing Marks"
                    ),
                    tp_whoCanAttendTraining: Yup.mixed().test(
                      "invalidInput",
                      "Please Select Who Can Attend Training",
                      value => {
                        if (value) {
                          return value.length
                        } else {
                          return false
                        }
                      }
                    ),
                    tp_certificateTemplate: Yup.mixed().test(
                      "fileNotSelected",
                      "Please Select Certificat Picture",
                      value => {
                        return (
                          isEdit ||
                          !!form?.["tp_certificateTemplate"]?.file?.type
                        )
                      }
                    ),
                    // .test("fileFormat", "Unsupported Format", value => {
                    //   if (!form?.["tp_certificateTemplate"]?.file?.type)
                    //     return isEdit || false

                    //   return [
                    //     "image/png",
                    //     "image/jpg",
                    //     "image/jpeg",
                    //     "image/gif",
                    //     "pdf/pdf"
                    //   ].includes(form?.["tp_certificateTemplate"].file.type)
                    // }),
                    tp_programImage: Yup.mixed()
                      .test(
                        "fileNotSelected",
                        "Please Select Program Picture",
                        value => {
                          return (
                            isEdit || !!form?.["tp_programImage"]?.file?.type
                          )
                        }
                      )
                      .test("fileFormat", "Unsupported Format", value => {
                        if (!form?.["tp_programImage"]?.file?.type)
                          return isEdit || false

                        return [
                          "image/png",
                          "image/jpg",
                          "image/jpeg",
                          "image/gif",
                        ].includes(form?.["tp_programImage"].file.type)
                      }),
                    tp_programDetailTemplate: Yup.mixed()
                      .test(
                        "fileNotSelected",
                        "Please Select Program Picture",
                        value => {
                          return (
                            isEdit ||
                            !!form?.["tp_programDetailTemplate"]?.file?.type
                          )
                        }
                      )
                      .test("fileFormat", "Unsupported Format", value => {
                        if (!form?.["tp_programDetailTemplate"]?.file?.type)
                          return isEdit || false

                        return [
                          "image/png",
                          "image/jpg",
                          "image/jpeg",
                          "image/gif",
                          "application/pdf",
                        ].includes(form?.["tp_programDetailTemplate"].file.type)
                      }),
                    tp_price: !isProgramFree
                      ? Yup.number()
                          .required("Please Enter Price Of Program")
                          .min(1, "Program Price Can not Be Less Than 1")
                      : Yup.mixed().optional(),
                  })}
                  onSubmit={values => {
                    let trainingProgramData = values
                    let { tp_whoCanAttendTraining: whoCanAttendTraining } =
                      values
                    whoCanAttendTraining = whoCanAttendTraining.map(data => {
                      return data.value
                    })

                    trainingProgramData.tp_whoCanAttendTraining =
                      whoCanAttendTraining.toString()

                    trainingProgramData.tp_description =
                      trainingProgramData.tp_description_html

                    trainingProgramData.tp_whoCanAttend =
                      trainingProgramData.tp_whoCanAttend_html

                    trainingProgramData.tp_benefitsOfProgram =
                      trainingProgramData.tp_benefitsOfProgram_html
                    if (isProgramFree) {
                      values.tp_price = null
                    }
                    if (isEdit) {
                      const programId = trainingProgramData["tp_id"]
                      trainingProgramData["tp_certificateTemplate"] =
                        form.tp_certificateTemplate_old
                      trainingProgramData["tp_certificateTemplate"] =
                        form.tp_certificateTemplate.file

                      trainingProgramData["tp_programImage"] =
                        form.tp_programImage_old
                      trainingProgramData["tp_programImage"] =
                        form.tp_programImage.file

                      trainingProgramData["tp_programDetailTemplate"] =
                        form.tp_programDetailTemplate_old
                      trainingProgramData["tp_programDetailTemplate"] =
                        form.tp_programDetailTemplate.file

                      delete trainingProgramData["tp_id"]
                      return handleEditTrainingProgramSubmit(
                        programId,
                        trainingProgramData
                      )
                    } else {
                      trainingProgramData["tp_certificateTemplate"] =
                        form.tp_certificateTemplate.file
                      trainingProgramData["tp_programImage"] =
                        form.tp_programImage.file
                      trainingProgramData["tp_programDetailTemplate"] =
                        form.tp_programDetailTemplate.file
                      return handleAddTrainingProgramSubmit(trainingProgramData)
                    }
                  }}
                >
                  {({
                    touched,
                    errors,
                    values,
                    handleSubmit,
                    handleBlur,
                    setFieldValue,
                    setFieldTouched,
                    handleChange,
                    validateOnChange,
                  }) => (
                    <>
                      <Form
                        onSubmit={e => {
                          e.preventDefault()
                          handleSubmit(e)
                          return false
                        }}
                      >
                        <Row>
                          <Col>
                            <Row className="mb-3">
                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Label className="form-label">
                                  Program Title
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="tp_programTitle"
                                  type="text"
                                  placeholder="Enter Training Program Title"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.tp_programTitle &&
                                    errors.tp_programTitle
                                  }
                                  value={values.tp_programTitle}
                                />
                                {touched.tp_programTitle &&
                                  errors.tp_programTitle && (
                                    <FormFeedback>
                                      {errors.tp_programTitle}
                                    </FormFeedback>
                                  )}
                              </Col>
                              {/* type of program */}
                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Label className="form-label">
                                  Type Of Program
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="tp_typeOfProgram"
                                  type="select"
                                  className="form-select"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.tp_typeOfProgram || 0}
                                  invalid={
                                    touched.tp_typeOfProgram &&
                                    errors.tp_typeOfProgram
                                      ? true
                                      : false
                                  }
                                >
                                  <option disabled value={0}>
                                    Select Type
                                  </option>
                                  {programType.map(val => {
                                    return (
                                      <option key={val} value={val}>
                                        {val}
                                      </option>
                                    )
                                  })}
                                </Input>
                                {touched.tp_typeOfProgram &&
                                errors.tp_typeOfProgram ? (
                                  <FormFeedback type="invalid">
                                    {errors.tp_typeOfProgram}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                              <Col md={4} sm={6} xs={12} className="mb-3">
                                <Label className="form-label">
                                  Who Can Attend Training
                                  <span className="text-danger">*</span>
                                </Label>

                                <Select
                                  name="tp_whoCanAttendTraining"
                                  options={genreOptions}
                                  value={values.tp_whoCanAttendTraining}
                                  validate={{
                                    required: { value: true },
                                  }}
                                  onChange={value => {
                                    setFieldValue(
                                      "tp_whoCanAttendTraining",
                                      value ? value : ""
                                    )
                                  }}
                                  onBlur={evt => {
                                    setFieldTouched(
                                      "tp_whoCanAttendTraining",
                                      true,
                                      true
                                    )
                                  }}
                                  className="react-select-container"
                                  classNamePrefix="select2-selection"
                                  // invalid={
                                  //   !!touched.tp_whoCanAttendTraining &&
                                  //   !!errors.tp_whoCanAttendTraining
                                  // }
                                  invalid={
                                    touched.tp_whoCanAttendTraining &&
                                    errors.tp_whoCanAttendTraining
                                      ? true
                                      : false
                                  }
                                  placeholder="Type to search..."
                                  isMulti
                                  isClearable
                                  isSearchable
                                />
                                {!!touched.tp_whoCanAttendTraining &&
                                !!errors.tp_whoCanAttendTraining ? (
                                  <div className="invalid-react-select-dropdown">
                                    {errors.tp_whoCanAttendTraining}
                                  </div>
                                ) : null}
                              </Col>
                              <Col sm={12} md={12} xs={12}>
                                <Row>
                                  <Col className="mt-2 mt-md-0 mb-3">
                                    <Label className="form-label">
                                      Description
                                      <span className="text-danger">*</span>
                                    </Label>

                                    <Col lg="12">
                                      <MDEditor
                                        renderHTML={text =>
                                          mdParser.render(text)
                                        }
                                        onChange={({ text, html }) => {
                                          setForm({
                                            ...form,
                                            tp_description: text,
                                            tp_description_html: html,
                                          })
                                          setTemp(!temp)
                                        }}
                                        placeholder="Type Something"
                                        value={form.tp_description}
                                        readOnly={false}
                                        view={{
                                          menu: true,
                                          md: true,
                                          html: true,
                                        }}
                                        theme="bubble"
                                      />
                                    </Col>

                                    {touched.tp_description &&
                                      errors.tp_description && (
                                        <FormFeedback>
                                          {errors.tp_description}
                                        </FormFeedback>
                                      )}
                                  </Col>
                                </Row>
                              </Col>

                              <Col
                                md={6}
                                sm={12}
                                xs={12}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Label className="form-label">
                                  Who Can Attend?
                                  <span className="text-danger">*</span>
                                </Label>
                                <Col lg="12">
                                  <MDEditor
                                    placeholder="Type Something"
                                    value={form.tp_whoCanAttend}
                                    renderHTML={text => mdParser.render(text)}
                                    readOnly={false}
                                    view={{
                                      menu: true,
                                      md: true,
                                      html: true,
                                    }}
                                    theme="bubble"
                                    onChange={({ text, html }) => {
                                      setForm({
                                        ...form,
                                        tp_whoCanAttend: text,
                                        tp_whoCanAttend_html: html,
                                      })
                                    }}
                                  />
                                </Col>

                                {touched.tp_whoCanAttend &&
                                  errors.tp_whoCanAttend && (
                                    <FormFeedback>
                                      {errors.tp_whoCanAttend}
                                    </FormFeedback>
                                  )}
                              </Col>

                              <Col sm={12} md={6}>
                                <Row>
                                  <Col className="mt-2 mt-md-0 mb-3">
                                    <Label className="form-label">
                                      Benefits Of Program
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <MDEditor
                                      placeholder="Type Something"
                                      value={form.tp_benefitsOfProgram}
                                      renderHTML={text => mdParser.render(text)}
                                      readOnly={false}
                                      view={{
                                        menu: true,
                                        md: true,
                                        html: true,
                                      }}
                                      theme="bubble"
                                      onChange={({ text, html }) => {
                                        let temp = form
                                        // temp["tp_benefitsOfProgram"] = text
                                        setForm(pre => ({
                                          ...form,
                                          tp_benefitsOfProgram: text,
                                          tp_benefitsOfProgram_html: html,
                                        }))
                                        // setForm({
                                        //   ...form,
                                        //   tp_benefitsOfProgram: text,
                                        //   tp_benefitsOfProgram_html: html,
                                        // })
                                      }}
                                    />
                                    {touched.tp_benefitsOfProgram &&
                                      errors.tp_benefitsOfProgram && (
                                        <FormFeedback>
                                          {errors.tp_benefitsOfProgram}
                                        </FormFeedback>
                                      )}
                                  </Col>
                                </Row>
                              </Col>

                              <Col sm={12} md={12} xs={12}>
                                <Row>
                                  <Col className="mt-2 mt-md-0 mb-3 col-12 col-sm-12 col-md-4">
                                    <Label className="form-label">
                                      Certificat Template
                                      <span className="text-danger">*</span>
                                      {isEdit &&
                                        form?.tp_certificateTemplate_old && (
                                          <>
                                            <span className="ms-2">
                                              (
                                              <a
                                                href={`${IMAGE_URL}/${
                                                  form?.tp_certificateTemplate_old ||
                                                  ""
                                                }`}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                Saved Certificate Template
                                              </a>
                                              )
                                            </span>
                                          </>
                                        )}
                                    </Label>
                                    <Input
                                      name="tp_certificateTemplate"
                                      type="file"
                                      accept=".png, .jpg, .jpeg, .gif"
                                      placeholder="Select Profile Pic"
                                      onChange={e => {
                                        let tempForm = form
                                        tempForm["tp_certificateTemplate"][
                                          "fileName"
                                        ] = e.target.value
                                        tempForm["tp_certificateTemplate"][
                                          "file"
                                        ] = e.target.files[0]
                                        setForm(tempForm)
                                      }}
                                      // onBlur={handleBlur}
                                      invalid={
                                        touched.tp_certificateTemplate &&
                                        errors.tp_certificateTemplate
                                      }
                                      defaultValue={
                                        form.tp_certificateTemplate?.fileName
                                      }
                                    />
                                    {touched.tp_certificateTemplate &&
                                      errors.tp_certificateTemplate && (
                                        <FormFeedback>
                                          {errors.tp_certificateTemplate}
                                        </FormFeedback>
                                      )}
                                  </Col>
                                  <Col className="mt-2 mt-md-0 mb-3 col-12 col-sm-12 col-md-4">
                                    <Label className="form-label">
                                      Program Image
                                      <span className="text-danger">*</span>
                                      {isEdit && form?.tp_programImage_old && (
                                        <>
                                          <span className="ms-2">
                                            (
                                            <a
                                              href={`${IMAGE_URL}/${
                                                form?.tp_programImage_old || ""
                                              }`}
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              Saved Certificate Template
                                            </a>
                                            )
                                          </span>
                                        </>
                                      )}
                                    </Label>
                                    <Input
                                      name="tp_programImage"
                                      type="file"
                                      accept=".png, .jpg, .jpeg, .gif"
                                      placeholder="Select Program Pic"
                                      onChange={e => {
                                        let tempForm = form
                                        tempForm["tp_programImage"][
                                          "fileName"
                                        ] = e.target.value
                                        tempForm["tp_programImage"]["file"] =
                                          e.target.files[0]
                                        setForm(tempForm)
                                      }}
                                      invalid={
                                        touched.tp_programImage &&
                                        errors.tp_programImage
                                      }
                                      defaultValue={
                                        form.tp_programImage?.fileName
                                      }
                                    />
                                    {touched.tp_programImage &&
                                      errors.tp_programImage && (
                                        <FormFeedback>
                                          {errors.tp_programImage}
                                        </FormFeedback>
                                      )}
                                  </Col>
                                  <Col className="mt-2 mt-md-0 mb-3 col-12 col-sm-12 col-md-4">
                                    <Label className="form-label">
                                      Program Detail Template
                                      <span className="text-danger">*</span>
                                      {isEdit &&
                                        form?.tp_programDetailTemplate_old && (
                                          <>
                                            <span className="ms-2">
                                              (
                                              <a
                                                href={`${IMAGE_URL}/${
                                                  form?.tp_programDetailTemplate_old ||
                                                  ""
                                                }`}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                Saved Certificate Template
                                              </a>
                                              )
                                            </span>
                                          </>
                                        )}
                                    </Label>
                                    <Input
                                      name="tp_programDetailTemplate"
                                      type="file"
                                      accept=".png, .jpg, .jpeg, .gif, .pdf"
                                      placeholder="Select Program Pic"
                                      onChange={e => {
                                        let tempForm = form
                                        tempForm["tp_programDetailTemplate"][
                                          "fileName"
                                        ] = e.target.value
                                        tempForm["tp_programDetailTemplate"][
                                          "file"
                                        ] = e.target.files[0]
                                        setForm(tempForm)
                                      }}
                                      invalid={
                                        touched.tp_programDetailTemplate &&
                                        errors.tp_programDetailTemplate
                                      }
                                      defaultValue={
                                        form.tp_programDetailTemplate?.fileName
                                      }
                                    />
                                    {touched.tp_programDetailTemplate &&
                                      errors.tp_programDetailTemplate && (
                                        <FormFeedback>
                                          {errors.tp_programDetailTemplate}
                                        </FormFeedback>
                                      )}
                                  </Col>
                                </Row>
                              </Col>
                              <Row>
                                <Col
                                  sm={12}
                                  md={4}
                                  xs={12}
                                  className="mt-2 mt-md-0 mb-3"
                                >
                                  <Label className="form-label">
                                    Duration(In Minutes)
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    name="tp_duration"
                                    type="text"
                                    placeholder="Duration"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    invalid={
                                      touched.tp_duration && errors.tp_duration
                                    }
                                    value={values.tp_duration}
                                  />
                                  {touched.tp_duration &&
                                    errors.tp_duration && (
                                      <FormFeedback>
                                        {errors.tp_duration}
                                      </FormFeedback>
                                    )}
                                </Col>
                                <Col
                                  sm={12}
                                  md={4}
                                  xs={12}
                                  className="mt-2 mt-md-0 mb-3"
                                >
                                  <Label className="form-label">
                                    Total Marks
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    name="tp_totalMarks"
                                    type="text"
                                    placeholder="Total Marks"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    invalid={
                                      touched.tp_totalMarks &&
                                      errors.tp_totalMarks
                                    }
                                    value={values.tp_totalMarks}
                                  />
                                  {touched.tp_totalMarks &&
                                    errors.tp_totalMarks && (
                                      <FormFeedback>
                                        {errors.tp_totalMarks}
                                      </FormFeedback>
                                    )}
                                </Col>
                                <Col
                                  sm={12}
                                  md={4}
                                  xs={12}
                                  className="mt-2 mt-md-0 mb-3"
                                >
                                  <Label className="form-label">
                                    Passing Marks
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    name="tp_passingMarks"
                                    type="text"
                                    placeholder="Passing Marks"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    invalid={
                                      touched.tp_passingMarks &&
                                      errors.tp_passingMarks
                                    }
                                    value={values.tp_passingMarks}
                                  />
                                  {touched.tp_passingMarks &&
                                    errors.tp_passingMarks && (
                                      <FormFeedback>
                                        {errors.tp_passingMarks}
                                      </FormFeedback>
                                    )}
                                </Col>
                                <Col
                                  sm={12}
                                  md={4}
                                  xs={12}
                                  className="mt-2 mt-md-0 mb-3"
                                >
                                  <Label className="form-label">
                                    Marks Per Question
                                    <span className="text-danger">*</span>
                                  </Label>
                                  {/* <Input
                                    name="tp_marksPerQuestion"
                                    type="select"
                                    className="form-select"
                                    placeholder="Marks Per Question"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.tp_marksPerQuestion || 0}
                                    invalid={
                                      touched.tp_marksPerQuestion &&
                                      errors.tp_marksPerQuestion
                                        ? true
                                        : false
                                    }
                                  >
                                    <option disabled value={0}>
                                      Select Marks
                                    </option>
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                    <option value={3}>3</option>
                                    <option value={4}>4</option>
                                    <option value={5}>5</option>
                                    <option value={6}>6</option>
                                    <option value={7}>7</option>
                                    <option value={8}>8</option>
                                    <option value={9}>9</option>
                                    <option value={10}>10</option>
                                  </Input> */}
                                  <Input
                                    name="tp_marksPerQuestion"
                                    type="text"
                                    placeholder="Marks Per Quetion"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    invalid={
                                      touched.tp_marksPerQuestion &&
                                      errors.tp_marksPerQuestion
                                    }
                                    value={values.tp_marksPerQuestion}
                                  />
                                  {touched.tp_marksPerQuestion &&
                                  errors.tp_marksPerQuestion ? (
                                    <FormFeedback type="invalid">
                                      {errors.tp_marksPerQuestion}
                                    </FormFeedback>
                                  ) : null}
                                </Col>
                                <Col md={6} sm={8} xs={12} className="mb-3">
                                  <Row>
                                    <Col className="gap-3">
                                      <Label className="form-label">
                                        Type
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <div>
                                        <RBSForm.Check
                                          id="type-free"
                                          name="tp_isFree"
                                          type="radio"
                                          inline
                                          label="Free"
                                          onChange={event => {
                                            if (event.target.checked) {
                                              let temp = form
                                              temp["tp_isFree"] = true
                                              values["tp_isFree"] = true
                                              setIsProgramFree(true)
                                              setForm(temp)
                                            }
                                          }}
                                        />
                                        <RBSForm.Check
                                          id="type-paid"
                                          name="tp_isFree"
                                          type="radio"
                                          inline
                                          label="Paid"
                                          onChange={event => {
                                            if (event.target.checked) {
                                              let temp = form
                                              temp["tp_isFree"] = false
                                              values["tp_isFree"] = false
                                              setIsProgramFree(false)
                                              setForm(temp)
                                            }
                                          }}
                                        />
                                      </div>

                                      {touched.tp_isFree &&
                                        errors.tp_isFree && (
                                          <FormFeedback>
                                            {errors.tp_isFree}
                                          </FormFeedback>
                                        )}
                                    </Col>
                                    {!isProgramFree ? (
                                      <Col
                                        md={6}
                                        sm={8}
                                        xs={12}
                                        className="mb-3"
                                      >
                                        <Label className="form-label">
                                          Program Price
                                          <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                          name="tp_price"
                                          type="number"
                                          rows={6}
                                          placeholder="Enter Program Price "
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          invalid={
                                            !!touched.tp_price &&
                                            !!errors.tp_price
                                          }
                                          value={values.tp_price}
                                        />
                                        {!!touched.tp_price &&
                                          !!errors.tp_price && (
                                            <FormFeedback>
                                              {errors.tp_price}
                                            </FormFeedback>
                                          )}
                                      </Col>
                                    ) : (
                                      <></>
                                    )}
                                  </Row>
                                </Col>
                              </Row>
                            </Row>

                            <Row className="mb-3"></Row>

                            <Row className="mb-3 text-center">
                              <Col>
                                <Button
                                  size="md"
                                  color="danger"
                                  type="button"
                                  disabled={submitLoading}
                                  className="mx-2"
                                  onClick={() => {
                                    props.history.push("/training-program")
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  size="md"
                                  color="info"
                                  type="submit"
                                  disabled={submitLoading}
                                >
                                  {!isEdit ? "Save" : "Update"}
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                    </>
                  )}
                </Formik>
              </CardBody>
            </Card>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

TrainingProgramModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default TrainingProgramModal
